import { format, formatDistanceToNow, parse } from "date-fns";
import { z } from "zod";

// Case categories in ServiceNow
export const CASE_CATEGORY = {
  FAULT: {
    label: "Fault",
    value: 5,
  },
  INFORMATION_REQUEST: {
    label: "Information Request",
    value: 6,
  },
  CHANGE_REQUEST: {
    label: "Change Request",
    value: 7,
  },
};

// Map the status ID returned from SN to a string display status
export const mapStatus = (status?: number | "") => {
  if (!status) {
    return "";
  }

  switch (status) {
    case 1:
      return "New";
    case 2:
      return "In progress";
    case 3:
      return "Closed";
    case 4:
      return "On hold";
    case 5:
      return "In progress";
    case 6:
      return "Resolved";
    case 9:
      return "Resolved";
    case 10:
      return "Open";
    case 18:
      return "On hold";
    default:
      return "Open";
  }
};

// Create the opened on on message for tickets and requests
export const openedMessage = (type?: string, date?: string) => {
  if (!date || !type) {
    return null;
  }

  try {
    const formattedDate = format(
      new Date(`${date}+00:00`),
      "E, dd MMM HH:mm:ss"
    );

    if (!formattedDate) {
      return null;
    }

    return `${type} opened ${formatDistanceToNow(
      new Date(`${date}+00:00`)
    )} ago (${formattedDate})`;
  } catch {
    return "";
  }
};

// Create the opened closed on message for tickets and requests
export const closedMessage = (type?: string, date?: string) => {
  if (!date || !type) {
    return null;
  }

  return `This ${type} was closed ${formatDistanceToNow(
    new Date(`${date}+00:00`)
  )} ago (${format(new Date(`${date}+00:00`), "E, dd MMM HH:mm:ss")})`;
};

// Generate a full location string from different location parts
export const generateLocation = (
  room?: string,
  rack?: string,
  floor?: string
) => {
  if (!room && !rack && !floor) {
    return "";
  }

  let location = "";

  if (room) {
    if (rack || floor) {
      location += `${room}, `;
    } else {
      location += room;
    }
  }

  if (rack) {
    if (floor) {
      location += `${rack}, `;
    } else {
      location += rack;
    }
  }

  if (floor) {
    location += floor;
  }

  return location;
};

// Shamelessly taken from https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
export const getHumanReadableFileSize = (bytes: string, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  let byteNumber = Number(bytes);

  if (Math.abs(byteNumber) < thresh) {
    return `${byteNumber} B`;
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    byteNumber /= thresh;
    u += 1;
  } while (
    Math.round(Math.abs(byteNumber) * r) / r >= thresh &&
    u < units.length - 1
  );

  return `${byteNumber.toFixed(dp)} ${units[u]}`;
};

export const mapFieldsToLabels: { [key: string]: string } = {
  whenDidThisIssueBeginDate: "When did this issue begin?",
  faultSummary: "Fault Summary",
  fault: "Fault Type",
  recentChanges:
    "Have any recent changes been made to impacted site or device?",
  recentChangesElaborate: "Please elaborate on the changes",
  siteAccessDayFrom: "Site access times",
  contactName: "Site contacts",
  powerAndLEDStatus: "Power and LED status",
  cablesAreSeatedProperly: "Cables are seated properly",
  deviceHasBeenRebooted: "Device has been rebooted",
  stackOrCluster: "Is this asset part of a stack or cluster?",
  operatingSystems:
    "Describe the operating system(s) involved in this issue, including the version number and patch level",
  peopleAffected: "How many people are affected?",
  currentlyExperiencing: "Are you currently experiencing this issue?",
  faultCausingSiteDown: "Is this fault causing an entire site to be hard down?",
  sourceIp: "Source IP",
  destinationIp: "Destination IP/URL",
  ipServicePort: "IP Service Port",
  pingAndTraceroute: "Please complete a Ping and Traceroute",
};

export const convertToDate = (timestamp?: string, dateFormat?: string) => {
  if (!timestamp) {
    return "";
  }

  let dateString = "";
  const stringFormat = dateFormat || "dd MMM yy";

  try {
    dateString = format(new Date(`${timestamp}+00:00`), stringFormat);
  } catch (e) {
    console.log(e);
  }

  return dateString;
};

export const convertToTime = (timestamp?: string, timeFormat?: string) => {
  if (!timestamp) {
    return "";
  }

  let timeString = "";
  const stringFormat = timeFormat || "HH:mm:ss";

  try {
    timeString = format(new Date(`${timestamp}+00:00`), stringFormat);
  } catch (e) {
    console.log(e);
  }

  return timeString;
};

export const formatToDate = (dateString: string) => {
  let formattedDate = "";

  if (!dateString) {
    return formattedDate;
  }

  try {
    const dateObject = parse(dateString, "yyyy-MM-dd", new Date());

    if (dateObject) {
      formattedDate = format(dateObject, "dd MMM yy");
    }
  } catch (e) {
    console.log(e);
  }

  return formattedDate;
};

export const extractValueFromLabelledText = (text: string, label: string) => {
  const regex = new RegExp(`${label}(.+?)(\n|$)`);

  const match = text.replace(/(<([^>]+)>)/gi, "").match(regex);

  // If we've got a value found, it'll be at index 1 - It's the first capture group that we're after
  if (match && match[1]) {
    return match[1].trim();
  }

  return undefined;
};

// Status filter options
export const statusOptions = [
  {
    label: "New",
    value: "new",
  },
  {
    label: "Open",
    value: "open",
  },
  {
    label: "On hold",
    value: "on-hold",
  },
  {
    label: "Resolved",
    value: "resolved",
  },
  {
    label: "Closed",
    value: "closed",
  },
];

// Filter a comma separated list of statuses into an array of options
export const filterStatus = (statusString?: string) => {
  if (!statusString) {
    return undefined;
  }

  const statuses = statusString.split(",");

  if (statuses?.length > 0) {
    const statusList: Array<{
      label: string;
      value: string;
    }> = [];

    statuses.forEach((status) => {
      const selectedOptions = statusOptions.filter(
        (statusOption) => statusOption.value === status
      );

      if (selectedOptions?.length > 0) {
        selectedOptions.forEach((option) => {
          statusList.push(option);
        });
      }
    });

    return statusList;
  }
};

export const generateStatusLabel = (statusLabel: string) => {
  let statusString = "";
  const statusList = filterStatus(statusLabel);

  if (statusList && statusList?.length > 0) {
    statusList?.forEach((listItem, index) => {
      statusString += listItem.label;
      if (statusList.length > index + 1) {
        statusString += ", ";
      }
    });
  }

  return statusString;
};

// Return an array of case types based on filters passed to an incident/request
export const getStatusParameterIds = (statusString: string) => {
  const statuses = statusString.split(",");
  const statusParam: number[] = [];

  if (statuses && statuses?.length > 0) {
    statuses?.forEach((status) => {
      switch (status) {
        case "new":
          statusParam.push(1);
          break;
        case "open":
          statusParam.push(10);
          break;
        case "on-hold":
          statusParam.push(18);
          break;
        case "resolved":
          statusParam.push(6);
          break;
        case "closed":
          statusParam.push(3);
          break;
        default:
          break;
      }
    });
  }
  return statusParam;
};

export const checkValidIp = (ipAddress?: string) => {
  if (!ipAddress) {
    return null;
  }

  const checkIfValid = z.string().ip().nullish().safeParse(ipAddress)?.success
    ? ipAddress
    : null;

  return checkIfValid;
};

export const setDeviceTypes = (serviceType?: string) => {
  if (!serviceType) {
    return [""];
  }

  switch (serviceType) {
    case "Wireless":
      return ["cmdb_ci_wap_network", "u_cmdb_ci_contract_inventory"];
    case "WAN":
      return [
        "u_cmdb_ci_ethernet",
        "u_cmdb_ci_dsl",
        "u_cmdb_ci_customer_router",
        "u_cmdb_ci_additional_product",
      ];
    case "Security":
      return ["cmdb_ci_ip_firewall", "u_cmdb_ci_contract_inventory"];
    case "LAN":
      return ["cmdb_ci_ip_switch", "u_cmdb_ci_contract_inventory"];
    default:
      return [];
  }
};

export const setContractInventoryDeviceTypes = (serviceType?: string) => {
  if (!serviceType) {
    return undefined;
  }

  switch (serviceType) {
    case "Wireless":
      return ["Access Point", "Controller"];
    case "Security":
      return ["Firewall", "NAC"];
    case "LAN":
      return ["Router", "Switch", "Server", "License"];
    default:
      return undefined;
  }
};

export const FAULTS = {
  CONNECTIVITY: {
    diagnosticValue: "No_Connectivity",
    icon: "connectivity",
    label: "No Connectivity",
  },
  HARDWARE: {
    diagnosticValue: null,
    icon: "hardware",
    label: "Hardware Fault",
  },
  PACKET_LOSS: {
    diagnosticValue: "Packet_Loss_or_Loss_of_Calls",
    icon: "intermittent",
    label: "Packet Loss/Loss of Calls",
  },
  REACHABILITY: {
    diagnosticValue: "Reachability_And_Accessibility",
    icon: "reachability",
    label: "Reachability and accessibility",
  },
  SLOW_SPEEDS: {
    diagnosticValue: "Slow_Speed",
    icon: "slow",
    label: "Slow Speeds",
  },
  SOFTWARE: {
    diagnosticValue: null,
    icon: "software",
    label: "Software Fault",
  },
};

export const getFaultTypeDiagnosticValue = (label?: string) => {
  return Object.values(FAULTS).find((_fault) => _fault.label === label)
    ?.diagnosticValue;
};

export const getFaultTypeIconName = (label?: string) => {
  return Object.values(FAULTS).find((_fault) => _fault.label === label)?.icon;
};

export const CG_SUPPORT_USER_DETAILS = {
  email: "support@convergencegroup.co.uk",
  id: "-1",
  name: "Convergence Group",
};

export const FILTER_SEPARATOR = "|";
