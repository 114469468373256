import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useSession } from 'next-auth/react';
import Logo from '@/icons/tiviti-logo-white.svg';
import SmallLogo from '@/icons/tiviti-logo-small.svg';
import Arrow from '@/icons/expand.svg';
import OrganisationIcon from '@/icons/customers.svg';
import DashboardIcon from '@/icons/dashboard.svg';
import IncidentsIcon from '@/icons/incidents.svg';
import InventoryIcon from '@/icons/inventory.svg';
import RequestsIcon from '@/icons/requests.svg';
import SitesIcon from '@/icons/sites.svg';
import OrdersIcon from '@/icons/orders.svg';
import EventsIcon from '@/icons/alerts.svg';
import { can } from '@/lib/permissions';
import { trpc } from '@/lib/trpc';
import { capitalise, INCIDENT } from '@/lib/labels';
import { FILTER_SEPARATOR } from '@/lib/tickets';

export default function Sidebar() {
  const [expanded, setExpanded] = useState(true);
  const session = useSession();
  const router = useRouter();

  const user = session?.data?.user;
  const { data: currentUser } = trpc.users.getCurrentUser.useQuery();

  const sidebarItems = [
    {
      name: "Dashboard",
      url: "/",
      icon: DashboardIcon as React.ReactNode,
      enabled: true,
      hidden:
        !user?.role ||
        !can(user.role, ["read_platform_info", "read_all_incidents"]),
    },
    {
      name: "Orders",
      url: "/orders",
      icon: OrdersIcon as React.ReactNode,
      enabled: true,
      hidden:
        !user?.role || !can(user.role, ["read_all_orders", "read_own_order"]),
    },
    {
      name: capitalise(INCIDENT.PLURAL),
      url:
        user?.role && can(user.role, "read_all_incidents")
          ? `/faults?status=new${FILTER_SEPARATOR}open${FILTER_SEPARATOR}on-hold`
          : "/faults",
      icon: IncidentsIcon as React.ReactNode,
      enabled: true,
      hidden:
        !user?.role ||
        !can(user.role, ["read_all_incidents", "read_own_incidents"]),
    },
    {
      name: "Requests",
      url:
        user?.role && can(user.role, "read_all_requests")
          ? `/requests?status=new${FILTER_SEPARATOR}open${FILTER_SEPARATOR}on-hold`
          : "/requests",
      icon: RequestsIcon as React.ReactNode,
      enabled: true,
      hidden:
        !user?.role ||
        !can(user.role, ["read_all_requests", "read_own_request"]),
    },
    {
      name: "Inventory",
      url: "/inventory",
      icon: InventoryIcon as React.ReactNode,
      enabled: true,
      hidden:
        !user?.role ||
        !can(user.role, ["read_all_devices", "read_own_devices"]),
    },
    {
      name: "Sites",
      url: "/sites",
      icon: SitesIcon as React.ReactNode,
      enabled: true,
      hidden:
        !user?.role || !can(user.role, ["read_all_sites", "read_own_sites"]),
    },
    {
      name: "Organisations",
      url: "/organisations",
      icon: OrganisationIcon as React.ReactNode,
      enabled: true,
      hidden: user?.role && !can(user.role, "read_all_customers"),
    },
    {
      name: "Organisation",
      url: `/organisations/${currentUser?.customer?.slug ?? ""}`,
      icon: OrganisationIcon as React.ReactNode,
      enabled: true,
      hidden:
        user?.role &&
        (!can(user.role, "read_own_customers") ||
          can(user.role, "read_all_customers")),
    },
    {
      name: "Events",
      url: `/events`,
      icon: EventsIcon as React.ReactNode,
      enabled: true,
      hidden: user?.role && !can(user.role, "read_system_errors"),
    },
  ];

  function getItemClassName(name: string) {
    // Replace spaces with hyphens and set to lowercase
    return name.replace(/\s+/g, '-').toLowerCase();
  }

  function isNavItemActive(url: string) {
    if (!url) {
      return false;
    }

    const urlWithoutParams = url.split('?')[0];

    return router.asPath.startsWith(urlWithoutParams);
  }

  return (
    <div className={clsx('sidebar', expanded && 'sidebar--expanded')}>
      <figure className="sidebar__figure">
        <Link className="sidebar__logo-link" href="/" title="Dashboard">
          {expanded ? (
            <Logo className="sidebar__logo sidebar__logo--large" />
          ) : (
            <SmallLogo className="sidebar__logo sidebar__logo--small" />
          )}
        </Link>
      </figure>

      <button
        aria-label="Expand/Close"
        className={clsx(
          'sidebar__expand',
          expanded && 'sidebar__expand--expanded'
        )}
        type="button"
        onClick={() => setExpanded(!expanded)}
        title="Expand/Close"
      >
        <Arrow
          className={clsx(
            'sidebar__arrow',
            expanded && 'sidebar__arrow--expanded'
          )}
        />
      </button>

      <ul
        className={clsx('sidebar__menu', expanded && 'sidebar__menu--expanded')}
      >
        {sidebarItems.map((item) => {
          const Icon = item.icon as React.ElementType;

          if (item.hidden) {
            return null;
          }

          return (
            <li className="sidebar__item" key={item.name}>
              {!item.enabled ? (
                <p
                  className={clsx(
                    'sidebar__link sidebar__link--disabled',
                    expanded && 'sidebar__link--expanded'
                  )}
                >
                  <Icon
                    className={clsx(
                      `sidebar__icon sidebar__icon--${getItemClassName(
                        item.name
                      )}`,
                      expanded && 'sidebar__icon--expanded'
                    )}
                  />

                  <span
                    className={clsx(
                      'sidebar__label',
                      expanded && 'sidebar__label--expanded'
                    )}
                  >
                    {item.name}
                  </span>

                  <span
                    className={clsx(
                      'sidebar__soon',
                      expanded && 'sidebar__soon--expanded'
                    )}
                  >
                    Soon
                  </span>
                </p>
              ) : (
                <Link
                  className={clsx(
                    'sidebar__link',
                    expanded && 'sidebar__link--expanded',
                    item.url !== '/'
                      ? isNavItemActive(item.url)
                        ? 'sidebar__link--active'
                        : ''
                      : router.asPath === '/' && 'sidebar__link--active'
                  )}
                  href={item.url}
                >
                  <Icon
                    className={clsx(
                      `sidebar__icon sidebar__icon--${getItemClassName(
                        item.name
                      )}`,
                      expanded && 'sidebar__icon--expanded'
                    )}
                  />

                  <span
                    className={clsx(
                      'sidebar__label',
                      expanded && 'sidebar__label--expanded',
                      item?.name &&
                        `sidebar__label--${getItemClassName(item.name)}`
                    )}
                  >
                    {item.name}
                  </span>

                  <span className="sidebar__tooltip">{item.name}</span>
                </Link>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
